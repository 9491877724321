<template>
    <div id="app">
        <div class="grid_div">
            <!-- <van-nav-bar title="提交拆车件" left-text="返回" @click-left='$router.go(-1)' left-arrow></van-nav-bar> -->
            <div class="address_btn_div">
                <div >
                    <div class="address_div" @click="address.address_show=true">
                        当前区域：
                        <span style="font-size:14px;color:rgb(146 146 146);">{{fieldValue}}</span>
                    </div>
                    <myAddress @position='position'></myAddress>
                </div>
            </div>
            <van-index-bar>
                <div v-for="(item,index) in charData" :key="index">
                    <van-index-anchor :index="item"  />
                    <van-cell v-for="(t,i) in carPartData[item]" :key="i" :title="t.name" @click="partCheck(t)"  />    
                </div>
            </van-index-bar>
            <van-goods-action>
                <van-goods-action-icon icon="bag-o" style="margin:0px 10px;" text="提交列表" @click="showDetails" :badge="badgeNum" />
                <van-goods-action-icon :text="'￥'+badgeTotalPrice" style="font-size:16px;"/>
                <van-goods-action-button type="danger" :disabled='order&&order.details&&order.details.length==0' text="立即提交" @click="toSettlement"/>
            </van-goods-action>
            <van-action-sheet v-model="action.actionShow" title="已选择">
                <div class="content">
                    <van-cell-group v-if="order&&order.details">
                        <van-cell v-for="(item,index) in order.details" :key="index" :title="item.partName+' '+item.name+item.amount+'('+item.unit+')'" >
                            <template #label>
                                <span>单价：￥{{item.price}}</span>
                            </template>
                            <template #right-icon>
                                <van-stepper v-model="item.checkAmount" :default-value='0' :min='0' theme="round" button-size="22" disable-input @plus="stepAdd(item.price)" @minus="stepPer(item)"  @change='addDetails(item)' />
                            </template>
                        </van-cell>
                    </van-cell-group>
                </div>
            </van-action-sheet>
            <van-popup v-model="address.address_show" round position="bottom">
                <van-cascader
                    v-model="address.cascaderValue"
                    title="请选择所在地区"
                    :options="address.addr_options"
                    :field-names="{text: 'name',value: 'id',children: 'children',}"
                    @close="address.address_show = false"
                    @change="onAddrChange"
                    @finish="onAddrFinish"/>
            </van-popup>
            <van-empty description="描述文字" v-show="charData.length==0"></van-empty>
            <div style="height:100px;">
            </div>
        </div>
        <div class="info_div" v-show="infoShow">
            <div class="back_div" v-show="infoBtnShow" @click="closeInfo">
                <font><van-icon name="close" /> 点 击 返 回</font>
            </div>
            <div class="list_div">
                <van-cell-group title="规格">
                    <van-cell v-for="(item,index) in infoData" :key="index" :title="item.name+item.amount+'('+item.unit+')'" >
                        <template #label>
                            <span>单价：￥{{item.price}}</span>
                        </template>
                         <template #right-icon>
                            <van-stepper v-model="item.checkAmount" :default-value='0' :min='0' theme="round" button-size="22" disable-input @plus="stepAdd(item.price)" @minus="stepPer(item)"  @change='addDetails(item)' />
                        </template>
                    </van-cell>
                </van-cell-group>
            </div>
        </div>
    </div>
</template>
<script>
import { 
    IndexBar,
    IndexAnchor,
    Cell,
    Icon,
    CellGroup,
    GoodsAction,
    GoodsActionIcon,
    GoodsActionButton,
    Stepper,
    ActionSheet,
    Popup,
    Cascader,
    Empty,
    NavBar  } from 'vant';
import {getAddressList} from '../../api/common'
import {GetAllPart} from '../../api/provider'
import {providerCarpartOrder,providerInfo} from '../../store/provider'
import pinyin from '../../helper/ChinesePY.js'
import myAddress from '../../components/addressComponent.vue'
export default ({
    components:{
        [ActionSheet.name]: ActionSheet,
        [GoodsAction.name]: GoodsAction,
        [GoodsActionIcon.name]: GoodsActionIcon,
        [GoodsActionButton.name]: GoodsActionButton,
        [IndexBar.name]: IndexBar,
        [IndexAnchor.name]: IndexAnchor,
        [CellGroup.name]: CellGroup,
        [Stepper.name]: Stepper,
        [Cascader.name]: Cascader,
        [Popup.name]: Popup,
        [Cell.name]: Cell,
        [Icon.name]: Icon,
        [Empty.name]: Empty,
        [NavBar.name]: NavBar,
        myAddress
    },
    computed:{
        badgeNum:function(){
            var details = providerCarpartOrder.details;
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.number = 0;
            for(var i in details){
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.number+=details[i].checkAmount;
            }
            return this.number;
        },
        badgeTotalPrice:function(){
            var details = providerCarpartOrder.details;
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalPrice = 0;
            for(var i in details){
                var cAmount = details[i].checkAmount;
                var price = details[i].price;
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.totalPrice+=(price*100*cAmount)/100;
            }
            return this.totalPrice;
        }
    },
    data() {
        return {
            carPartData:[],
            infoData:[],
            charData:[],
            infoShow:false,
            infoBtnShow:false,
            address:{
                address_show:false,
                addr_options:[],
                cascaderValue:'',
            },
            fieldValue:providerCarpartOrder.fieldValue,
            action:{
                actionShow:false,
                actionData:'',
            },
            Obj:{},
            value:0,
            number:0,
            totalPrice:0,
            order:{},
        };
    },methods:{
        position(data,field){
            providerCarpartOrder.province = data.provinceId;
            providerCarpartOrder.city = data.cityId;
            providerCarpartOrder.country = data.countryId;
            providerCarpartOrder.fieldValue = field;
            this.fieldValue = field;
        },
        closeInfo(){
            var ss = document.querySelector("#app > div.info_div > div.list_div").clientWidth;
            this.infoBtnShow = false;
            var that = this;
            var i = setInterval(function(){
                ss-=6;
                document.querySelector("#app > div.info_div > div.list_div").style.width = ss+'px';
                if(ss<0){
                    window.clearInterval(i);
                    that.infoShow = false;
                }
            },1);
        },
        getFirstChar(name){
            if(!name){
                return '';
            }
            //pinyin.setOptions({checkPolyphone:false,charCase:0});
            var char = pinyin.getWordsCode(name);
            return char[0];
        },
        partCheck(t){
            this.infoShow = true;
            var body = document.querySelector("#app > div").clientWidth;
            var ss = 0;
            var that = this;
            var i = setInterval(function(){
                ss+=6;
                document.querySelector("#app > div.info_div > div.list_div").style.width = ss+'px';
                if(ss>=(body*0.9)){
                    that.infoBtnShow = true;
                    window.clearInterval(i);
                }
            },1);
            var details = providerCarpartOrder.details;
            for(var item in t.specifications){
                var info = t.specifications[item];
                var index = details.findIndex(a=>a.id == info.id && a.partId == info.partId);
                if(index!=-1){
                    t.specifications[item] = details[index];
                }else{
                    t.specifications[item].checkAmount = 0;
                }
                info.partName = t.name;
            }
            this.infoData = t.specifications;
        },
        stepAdd(){
        },
        stepPer(item){
            var details = providerCarpartOrder.details;
            var index = details.findIndex(a=>a.id == item.id && item.partId == a.partId);
            if(index>-1&&item.checkAmount<=1){
                details.splice(index,1);
            } 
            this.order.details = details
        },
        addDetails(t){
            this.number=-1;
            this.totalPrice=-1;
            var details = providerCarpartOrder.details;
            var index = details.findIndex(a=>a.id == t.id && t.partId == a.partId);
            if(index==-1&&t.checkAmount>0){
                details.push(t);
            }
            this.order.details = details
            console.log(this.order);
        },
        toSettlement(){
           this.$router.push('/userPart/settlement');
        },
        showDetails(){
            //this.action.actionData =
            this.action.actionShow = true;
        },
        onAddrChange({ value,selectedOptions  }){
            this.initCity(value,selectedOptions);
        },
        onAddrFinish({ selectedOptions }){
            this.address.address_show = false;
            this.address.addr_options = [];
            this.initCity();
            this.fieldValue = selectedOptions.map((option) => option.name).join('/');
            providerCarpartOrder.fieldValue = this.fieldValue;
            var arr = selectedOptions.map((option) => option.id);
            providerCarpartOrder.province = arr&&arr[0];
            providerCarpartOrder.city = arr&&arr[1];
            providerCarpartOrder.country = arr&&arr[2];
        },
        initCity(id,selectedOptions){
            var body = {id:id};
            getAddressList(body).then((res)=>{    
                res.forEach(e=>{
                    if(e.isParent){e.children = [];}
                });
                if(this.address.addr_options.length == 0||id == 0){
                    this.address.addr_options =res;
                }else{
                    if(selectedOptions&&selectedOptions.length!=0){
                        selectedOptions[selectedOptions.length-1].children=res;
                    }
                }
            });
        },
        initData(){
            var body = {
                province:providerCarpartOrder.province||'',
                city:providerCarpartOrder.city||''
            }            
            this.$toast.loading({duration: 0,forbidClick: true,message: '加载中'});
            GetAllPart(body).then(res=>{
                this.$toast.clear();
                var char = '';
                res.forEach(e => {
                    char = this.getFirstChar(e.name);
                    if(!this.carPartData[char]){
                        this.carPartData[char] = [];
                        this.charData.push(char);
                    }
                    this.carPartData[char].push(e);
                });
            });
        }
    },
    watch:{
        fieldValue(){
            this.number = 0;
            this.totalPrice = 0;
            this.carPartData = [];
            this.charData = [];
            providerCarpartOrder.details = [];
            this.initData();
        },
        number(val){
            providerCarpartOrder.number = val;
        },
        totalPrice(val){
            providerCarpartOrder.total_price = val;
        },
    },created(){
        providerCarpartOrder.providerId = providerInfo.get().id;
        this.order = providerCarpartOrder;
        this.initCity();                
    },mounted(){
        if(providerCarpartOrder.city&&providerCarpartOrder.province){
            this.fieldValue = providerCarpartOrder.fieldValue;
            this.initData();
        }else{
            // document.querySelector("#app > div.grid_div > div.address_btn_div > div > button").click();
        }
    }
})
</script>
<style scoped>
    .btns_div{
        height: 60px;
    }
    .address_div{
        display: inline-block;
        width: 70%;
        padding: 0px 20px;
        background: white;
        line-height: 32px;
        text-align: left;
    }
    .settlement_div{        
        text-align: right;
        border: 1px solid red;
        position: absolute;
        bottom: 50px;
        width: 100%;
    }
    .address_btn_div{
        /* padding-top: 10px; */
        margin-bottom: 5px;
        padding: 5px 0px;
        background-color: white;
    }
    .info_div{
        position: absolute;
        width: 100vw;
        height: 100%;
        top: 0px;
        left: 0px;
    }
    .back_div{
        /* padding: 80% 0px; */
        padding-top: 60%;
        left: 0px;
        position: fixed;
        height: 100vh;
        width: 10vw;
        background-color: black;
        opacity: 0.5;
    }
    .back_div font{
        text-align: center;
        color:white;
        /* font-size:14px; */
        writing-mode:vertical-rl;
    }
    .list_div{
        z-index: 2;
        position: absolute;
        right: 0px;
        width: 90vw;
        height: 100%;
        background-color: white;
    }
    .grid_div{
        width: 100vw;
        height: 100%;
        background-color: #f2f2f2;
    }
    .car_part_desc{
        height: 40px;
        line-height: 40px;
    }
</style>