<template>
<div>
    <van-button type="primary" 
      :loading='position_loading' 
      loading-type="spinner" 
      native-type="button" 
      hairline 
      color="#4600AF"
      size="small" 
      @click="position" style="float: right;margin-top: -38px;">
        <van-icon size="12" name="location-o" />定位
    </van-button>
    <div id="container"></div>
</div>
</template>
<script>
import { Button,Icon } from 'vant';
import wx from 'weixin-js-sdk';
import AMapLoader from '@amap/amap-jsapi-loader';
import {position} from '../api/common'
import {getWXinfo} from '../api/wxAuth'

export default {
    components:{
        [Button.name]: Button,
        [Icon.name]: Icon,
    },
    data(){
        return {
            position_loading:false,
            locationForm:{
                longitude:'',
                latitude:'',
            },
            map:null,
        }
    },
    methods:{
        //选择使用哪种方式加载
        position(){
            if(this.$global.isWeiXin){
                console.log('wx');
                this.wxPosition();
            }else{
                console.log('高德');
                this.gaodePosition();
            }
        },
        wxPosition(){
            this.position_loading = true;
            getWXinfo({url:window.location.href.split(',')[0]}).then(res=>{
            var data = res&&res.data;
            var config = {
                appId: data.appId, // 必填，公众号的唯一标识
                timestamp: data.timestamp, // 必填，生成签名的时间戳
                nonceStr: data.nonceStr, // 必填，生成签名的随机串
                signature: data.signature,// 必填，签名
                jsApiList: ['getLocation'] // 必填，需要使用的JS接口列表
            };
            console.log('res');
            console.log(res);
            wx.config(config);
            var that = this;
            wx.ready(function(){
                wx.getLocation({
                type: 'wgs84',
                    success (res) {
                        that.locationForm.latitude = res.latitude
                        that.locationForm.longitude = res.longitude
                        console.log('调用成功');
                        that.getlocationCity(that);
                        console.log(res);
                    },
                    fail(){
                        that.position_loading = false;
                        that.$toast('定位失败，请手动选择');
                    }
                })
            });
            })
        },
        getlocationCity(that){
            position({
                latitude:that.locationForm.latitude,
                longitude:that.locationForm.longitude
                }).then(result=>{
                console.log(result);
                that.position_loading = false;
                var data = result.data;
                if(data&&data.length!=0){
                    data = data[0];
                    that.fieldValue = data.province+"/"+data.city+"/"+data.country;
                    that.$emit('position',data,that.fieldValue);
                }else{
                    that.$toast('定位失败，请手动选择');
                }
            });
        },
        initMap(){
            AMapLoader.load({
                key:"9258566000a43d03c482e218d493794a",             // 申请好的Web端开发者Key，首次调用 load 时必填
                version:"2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins:[''],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            }).then((AMap)=>{
                this.map = AMap;
            }).catch(e=>{
                console.log(e);
            })
        },
        gaodePosition(){
            var AMap = this.map;
            this.position_loading = true;
            const that = this;
            const map = new AMap.Map('container', {
                resizeEnable: true
            });
            map.plugin('AMap.Geolocation', function () {
            const geolocation = new AMap.Geolocation({
                enableHighAccuracy: true,
                timeout: 10000,
                buttonOffset: new AMap.Pixel(10, 20),
                zoomToAccuracy: true,
                buttonPosition: 'RB'
            });
            geolocation.getCurrentPosition(function(status,result){
                if(status=='complete'){
                    onComplete(result)
                }else{
                    onError(result)
                }
            });
            function onComplete(data) {
                console.log('高德地图');
                console.log(data);
                that.locationForm.longitude = data.position.lng.toString();
                that.locationForm.latitude = data.position.lat.toString();
                that.getlocationCity(that);
            }
            function onError(data) {
                    console.log(data);
                    that.$toast('定位失败，请手动选择');
                    that.position_loading = false;
                }
            });
        },
    },
    mounted(){
        this.initMap();
    }

}
</script>

<style>

</style>